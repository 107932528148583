import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import address, { Address } from '@/shared/model/address';
import addressesImportSetting, { AddressesImportSetting } from '@/shared/model/addressesImportSetting';
import organisation, { Organisation } from '@/shared/model/organisation';
import EditOrganizationAddressIdentificationTypeDialog from './edit-organization-address-identification-type-dialog/edit-organization-address-identification-type-dialog.vue';
import ImportTableView from '@/views/settings/company/account-assignment/import-table/import-table.vue';

const name = 'addresses-view';
const logger = new Logger(name);
const addressModule = namespace('address');
const addressesImportSettingModule = namespace('addressesImportSetting');
const organizationModule = namespace('organization');

@Component({ name: name, components: { EditOrganizationAddressIdentificationTypeDialog, ImportTableView } })
export default class AddressesView extends Vue {
  @addressesImportSettingModule.Action('updateChangeClearBeforeImport')
  private updateChangeClearBeforeImport!: any;
  @addressesImportSettingModule.Getter('getInProgressUpdateAddressesImportSetting')
  private inProgressUpdateAddressesImportSetting!: boolean;
  @addressesImportSettingModule.Action('getAddressesImportSetting')
  private getAddressesImportSetting!: any;
  @addressesImportSettingModule.Getter('getAddressesImportSetting')
  private addressesImportSetting!: AddressesImportSetting;
  @addressesImportSettingModule.Getter('getAddressesImportSettingsIsLoading')
  private addressesImportSettingsIsLoading!: AddressesImportSetting;

  @addressModule.Action('getAddresses')
  private getAddresses!: any;
  @addressModule.Getter('getAddresses')
  private addresses!: OdataItems<Address>;
  @addressModule.Getter('getIsImporting')
  private isImporting!: boolean;
  @addressModule.Getter('getImportingFile')
  private importingFile!: any;
  @addressModule.Mutation('setImportingFile')
  private setImportingFile!: any;
  private isUpload: boolean = false;
  @addressModule.Action('uploadAddress')
  private uploadAddresses!: any;

  @organizationModule.Action('updateAddressIdentificationType')
  private updateAddressIdentificationType!: any;
  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('column0'), value: 'konto' },
      { text: this.$t('column1'), value: 'nachname' },
      { text: this.$t('column2'), value: 'vorname' },
      { text: this.$t('column3'), value: 'kennzeichen' },
      { text: this.$t('column4'), value: 'seriennummer' },
      { text: this.$t('column5'), value: 'anrede' },
      { text: this.$t('column6'), value: 'strasse' },
      { text: this.$t('column7'), value: 'plz' },
      { text: this.$t('column8'), value: 'ort' },
      { text: this.$t('column9'), value: 'tel1' },
      { text: this.$t('column10'), value: 'tel2' },
      { text: this.$t('column11'), value: 'mail1' },
      { text: this.$t('column12'), value: 'mail2' },
      { text: this.$t('column13'), value: 'zbv1' },
      { text: this.$t('column14'), value: 'zbv2' },
    ];
    return headers;
  }

  doUpdateAddressesImportSetting(item: Organisation) {
    this.updateAddressIdentificationType(item);
  }
  clickEditAddressIdentificationType() {
    this.openDialog(this.organization);
  } //#region Dialog logic
  dialogAddEdit: { show: boolean; model: Organisation } = {
    show: false,
    model: organisation.parse({}),
  };

  async dialogAddEditOnClose(folder: any) {
    this.dialogAddEdit.show = false;
  }

  dialogAddEditOnUpdate(item: any) {
    this.doUpdateAddressesImportSetting(item);
    this.dialogAddEdit.show = false;
  }

  private async openDialog(item: Organisation) {
    this.dialogAddEdit.model = { ...item };
    this.dialogAddEdit.show = true;
  }
  //#endregion
}
