import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';

const name = 'edit-tax-group-dialog';
const logger = new Logger(name);

@Component({
  name: name,
  components: {},
})
export default class EditTaxGroupDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => {} })
  private value!: any;

  private onClose() {
    this.$emit('click:close', this.value);
  }
  get items() {
    return [
      {
        value: 'kundennummer_kennzeichen_seriennummer',
        text: this.$t('kundennummer_kennzeichen_seriennummer'),
      },
      {
        value: 'kundennummer',
        text: this.$t('kundennummer'),
      },
    ];
  }
  private async onConfirm() {
    this.$emit('click:update', this.value);
  }
}
