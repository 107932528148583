import { render, staticRenderFns } from "./edit-organization-address-identification-type-dialog.html?vue&type=template&id=1051050d&scoped=true&external"
import script from "./edit-organization-address-identification-type-dialog.ts?vue&type=script&lang=ts&external"
export * from "./edit-organization-address-identification-type-dialog.ts?vue&type=script&lang=ts&external"
import style0 from "./edit-organization-address-identification-type-dialog.scss?vue&type=style&index=0&id=1051050d&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1051050d",
  null
  
)

/* custom blocks */
import block0 from "./edit-organization-address-identification-type-dialog.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Faccount-assignment%2Faddresses%2Fedit-organization-address-identification-type-dialog%2Fedit-organization-address-identification-type-dialog.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VDialog,VForm,VRow,VSelect,VSpacer,VToolbar})
